import mock from '../mock'
const btnhAPIUrl = "https://api.btnh.app/"
const data = {
  docData: {
    // Get lead Sources list
    leadSources: {
      icon: 'Disc',
      title: 'Lead Sources',
      subtitle: 'What are lead sources and what does it got to do with me?',
      qandA: [
        {
          question: 'What is "lead source"?',
          ans:
            'In BTNH, the leads sources are the unique identifier for the source of the lead, for example, if we receive leads from Facebook, the leads source can be for a single campaign, for example "Facebook Orlando, campaign 123". Each lead provider can have multiple lead sources, this way we can monitor what works and what doesn\'t work.'
        },
        {
          question: 'Can I add lead sources?',
          ans:
            'If you need to add more lead sources, just contact us at info@btnhurricane.com and request to add lead sources under your account.'
        },
        {
          question: 'Do I really need to use lead sources?',
          ans:
            'It depends, if you would like to add leads - you will need it. In every API request to add lead you will be required to specify the lead source id (the lead source id is the "value" in the object you will get).'
        },
        {
          question: 'How can I see what lead sources I have under my account? (API)',
          ans:
            `Thats an easy one, just send a GET request to ${btnhAPIUrl}getLeadSourcesList with your API key as a URl parameter.`
        },
        {
          question: 'What type of respond should I expect from the API? (API)',
          ans:
            `${btnhAPIUrl}getLeadSourcesList should return a 200 respond code with json data, the json data  will include an array with the lead sources as objects. \nexample: {[{value: 1, label: "lead source name"}, {value: 2, label: "lead source 2 name"}]} \n(You will need this "value" when you add new leads!). \nYou will receive an error 401 if your api key is not present / not valid / your user is not active. You might get an error if your account doesn\'t have lead sources under it.`
        },
        {
          question: 'What parameters should be included with this request? (API)',
          ans:
            `getLeadSourcesList accepts GET requests only, the get request should have one parameter, apiKey (case sensitive). The apiKey parameter needs to be indcluded in the Url. \nExample: ${btnhAPIUrl}getLeadSourcesList?apiKey="${localStorage.getItem('apiKey')}"`
        }
      ]
    },
    // Products List
    productsList: {
      icon: 'Package',
      title: 'Products Categories List',
      subtitle: 'Wnat is a product list and why do I need it?',
      qandA: [
        {
          question: 'What is product list?',
          ans:
            'In BTNH we sell many products, and we add new products all the time. Product list is simply a list of our products categories, for example, we sell many types of windows and may types of doors, when you get the product list you will get a list of the products categories, not individual products. For example: Windows And Doors, Solar etc.'
        },
        {
          question:
            'Why do I need to know the products categories you sell?',
          ans:
            'When you add a new lead, you will need to specify the category IDs that the customer is intrested in, that way we can provide a better service to the customer from the first call'
        },
        {
          question: 'How do I get the products categories?',
          ans:
          `Thats easy, just send a GET request to ${btnhAPIUrl}getProductCategories with your API key as a URl parameter.`
        },
        {
          question: 'What type of respond should I expect from the API? (API)',
          ans:
          `${btnhAPIUrl}getProductCategories should return a 200 respond code with json data, the json data will include an array with the products categories as objects. \nExample: {[{categoryID: 1, productCategory: "Windows & Doors"}, {value: 2, label: "Solar"}]} (You will need the "categoryID" when you add new leads!). You may receive an error 401 if your api key is not present / not valid or if your user is not active. You might get an error if your account doesn\'t have lead sources under it.`
        },
        {
          question: 'What parameters should be included with this request? (API)',
          ans:
            `getProductCategories accepts GET requests only, the get request should have one parameter, apiKey (case sensitive). The apiKey parameter needs to be indcluded in the Url. \nExample: ${btnhAPIUrl}getProductCategories?apiKey="${localStorage.getItem('apiKey')}}"`
        }
      ]
    },
    // All Leads
    getAllLeads: {
      icon: 'FileText',
      title: 'Get My Leads',
      subtitle: 'How do I access my leads data?',
      qandA: [
        {
          question: 'what\'s included in "my leads"?',
          ans:
          'Your leads are all of the leads that are under the leads sources that are under your account, it includes leads you added using the API, GUI, and leads that were added by our team under one of your lead sources.'
        },
        {
          question: 'How do I get my leads?',
          ans:
            `To get your leads simply submit a GET request to ${btnhAPIUrl}getAllLeads  You will need to include your API key as a url parameter (the parameter is 'apiKey')`
        },
        {
          question: 'What type of respond should I expect from the API? (API)',
          ans:
          `${btnhAPIUrl}getAllLeads should return a 200 respond code with json data, the json data will include an array with your leads as objects. Example respond in the next tab (Example respond from the API).`
        },
        {
          question: 'Example respond from the API',
          ans:
          `[{"leadSource": {"value": 1, "label": "Facebook-Orlando"}, "customerAddressStreet1": "3225 Vannila Avenue", "customerAddressStreet2": "", "customerAddressCity": "Palm City", "customerAddressState": "Florida",
          "customerAddressZipCode": "12345", "customerAddressCounty": "Browny County", "customerServiceArea": {"value":
          "orlando", "label": "Orlando"}, "customerPhone1": "1234567890", "customerPhone2": "", "langPrefer": "Spanish",
          "customerIntrestInProducts": [{"value": 1, "label": "Windows & Doors"}, {"value": 2, "label": "Solar"}], "leadStatus":
          {"statusID": 3, "statusLabel": "Follow Up", "statusDate": 1636140365595, "statusBadgeColor": "warning"},
          "customerAddressFullDisplay": "3225 Vannila Avenue, Palm City, FL 12345, USA", "appointmentDate": 1634745600000,
          "leadCreationDate": 1634735960955, "leadID": 40, "closingData": {"selectedClosingOutcome": {"value": 1, "label": "PNS -
          Not Interested"}}}]`
        },
        {
          question: 'What parameters should be included with this request? (API)',
          ans:
            `getAllLeads accepts GET requests only, the get request should have one parameter, apiKey (case sensitive). The apiKey parameter needs to be indcluded in the Url. \nExample: ${btnhAPIUrl}getAllLeads?apiKey="${localStorage.getItem('apiKey')}"`
        }
       
      ]
    },
    // get lead by ID
    getLeadWithID: {
      icon: 'File',
      title: 'Get Lead By ID',
      subtitle: 'How can I get specific lead?',
      qandA: [
        {
          question: 'What is "Lead ID"?',
          ans:
            'Lead ID is the unique identifier every lead is assigned upon creation, lead would never have more then one Lead ID. Lead ID is your way to get specific leads from the API. When you create new lead / get your existing leads you will see a property named leadID, this is the lead ID.'
        },
        {
          question: 'How to request lead data by lead ID? (API)',
          ans:
            `To get a lead by lead ID just submit a GET request to ${btnhAPIUrl}getLeadWithId   with 2 parameters: apiKey and leadID. LeadID to be a integer value (exmpl: leadID=1234)`
        },
        {
          question: 'What type of respond should I expect from the API? (API)',
          ans:
            `${btnhAPIUrl}getLeadWithId   should return a 200 respond code with json data, the json data will include a single object with the lead data. Example respond in the next tab (Example respond from the API).`
        },
        {
          question: 'Example respond from the API',
          ans:
            `{"leadSource": {"value": 1, "label": "Facebook-Orlando"}, "customerAddressStreet1": "3225 Vannila Avenue", "customerAddressStreet2": "", "customerAddressCity": "Palm City", "customerAddressState": "Florida",
            "customerAddressZipCode": "12345", "customerAddressCounty": "Browny County", "customerServiceArea": {"value":
            "orlando", "label": "Orlando"}, "customerPhone1": "1234567890", "customerPhone2": "", "langPrefer": "Spanish",
            "customerIntrestInProducts": [{"value": 1, "label": "Windows & Doors"}, {"value": 2, "label": "Solar"}], "leadStatus":
            {"statusID": 3, "statusLabel": "Follow Up", "statusDate": 1636140365595, "statusBadgeColor": "warning"},
            "customerAddressFullDisplay": "3225 Vannila Avenue, Palm City, FL 12345, USA", "appointmentDate": 1634745600000,
            "leadCreationDate": 1634735960955, "leadID": 40, "closingData": {"selectedClosingOutcome": {"value": 1, "label": "PNS -
            Not Interested"}}}`
        },
        {
          question: 'What parameters should be included with this request? (API)',
          ans:
          `getLeadWithId accepts GET requests only, the get request should include 2 parameters, apiKey and leadID (case sensitive). The apiKey and leadID parameters needs to be indcluded in the Url.\nExample: ${btnhAPIUrl}getLeadWithId?apiKey="${localStorage.getItem('apiKey')}"&leadID=1234`
        }
      ]
    },
    // Add New Lead
    addNewLead: {
      icon: 'FilePlus',
      title: 'Add New Lead',
      subtitle: 'How do I add new leads?',
      qandA: [
        {
          question: 'What is lead exactly?',
          ans:
            'Lead is the customer data for example the phone number, customer name, address etc. The lead will be used by the call center to attempt to schedule an appointment with the customer.'
        },
        {
          question: 'How do I add leads to the DB?',
          ans:
            `To add leads to the DB you will need to submit a POST request to the API at ${btnhAPIUrl}addLead\n
            The parameters in this POST request should be in a json format in the request body! not in the headers of the request.\n
            Please check next tab (What parameters should be included with this request) to see the required and optional parameters.`
        },
        {
          question: 'What parameters should be included with this request? (API)',
          ans:
            `*leadSource - This required parameter should be an integer value, you can get the lead source by submitting a get request to getLeadSourcesList (see lead sources tab). The value used in the request is the value of the lead source - not the object itself \n
            *customerName - This required parameter should be a string value, this parameter is simply the customer full name. \n
            *customerPhone1 - This required parameter should be a string value, this is the customer phone number. This value should be a string with 10 digits only, no +1 is needed. \n
            customerPhone2 - This optional parameter should be a string value, this is the customer alternative phone number. This value should be a string with 10 digits only, no +1 is needed.\n
            langPrefer - This optional parameter should be one of these strings: ('English', 'Spanish', 'Unknown'). Not specifing this parameter will set the langPrefer as Unknown.\n
            customerAddressStreet1 - This optional parameter should be a string. This is the customer address streed 1 address.\n
            customerAddressStreet2 - This optional parameter should be a string. This is the customer address streed 2 address.\n
            customerAddressCity - This optional parameter should be a string. This is the customer city.\n
            customerAddressState - This optional parameter should be a string. This is the customer state.\n
            customerAddressZipCode - This optional parameter should be a string. This is the customer zip code.\n
            customerEmail - This optional parameter should be a string. This is the customer email address.\n
            leadNote - This optional parameter should be a string. This is a note that will be avilable in the lead, the call center will have visability of this note.\n
            \n
            See next tab (Example API request) for a full example of adding new lead to the DB.
            `
        },
        {
          question: 'example API request',
          ans:
            `API add lead request:\n
            (Please make sure to include the parameters and values as JSON in the request body)\n
            {\n
              "apiKey": "${localStorage.getItem('apiKey')}",\n
              "leadSource": "1",\n 
              "customerName": "john Doe",\n 
              "customerPhone1": "1234567890",\n 
              "customerPhone2": "0987654321",\n 
              "customerIntrestInProducts": [7],\n 
              "langPrefer": "English",\n 
              "customerAddressStreet1": "3225 Vannila Avenue",\n 
              "customerAddressStreet2": "apt 12",\n 
              "customerAddressCity": "Palm City"",\n
               "customerAddressState": "FL",\n 
               "customerAddressZipCode": "12345",\n 
               "customerEmail": "example@email.com",\n 
               "leadNote": "Anything you want to add to the lead notes?"\n 
              }
            `
        }, 
        {
          question: 'What type of respond should I expect from the API? (API)',
          ans:
          `${btnhAPIUrl}addLead   should return a 200 respond code with json data, the json data will include a single object with the request status (status), status message (statusMsg), new lead ID (leadID) and the new lead data (leadData) . Example respond in the next tab (Example respond from the API).`

        }, 
        {
          question: 'Example respond from the API',
          ans:
            `API add lead request:\n
            (Please make sure to include the parameters and values as JSON in the request body)\n
            {"status": "success", "statusMsg": "Lead Added", "leadID": 6843,\n
            "leadData": {"leadSource": {"value": 1, "label":
            "Community Care-Miami"}, "customerIntrestInProducts": [{"value": 7, "label": "HVAC"}], "customerName": "John Doe",
            "customerPhone1": "1234567890", "customerPhone2": "0987654321", "langPrefer": "English", "customerAddressStreet1": "3225 Vannila Avenue",
            "customerAddressStreet2": "apt 12", "customerAddressCity": "Palm City", "customerAddressState": "FL",
            "customerAddressZipCode": "12345", "customerEmail": "example@email.com", "leadProviderSelfManage": false,
            "leadCreatedByUser": "leadprovidertest", "leadStatus": {"statusID": 1, "statusLabel": "New", "statusDate":
            1651868961148, "statusBadgeColor": "primary"}, "leadCreationDate": 1651868961148, "customerAddressFullDisplay": "",
            "selectedSlotForMeeting": {"label": "Unassigned", "value": ""}, "assignedSalesman": {"label": "", "value": ""},
            "salesmanConfirmationSent": {"sent": false, "date": ""}, "appointmentDate": 0, "leadFiles": [{"id": "beforefolder",
            "name": "Before", "isDir": true, "filesInFolder": []}, {"id": "afterfolder", "name": "After", "isDir": true,
            "filesInFolder": []}], "leadCreatedByType": "API-User", "relatedLeads": [], "customerServiceArea": {"value": "",
            "label": ""}, "assignedDispatcherUser": "Unassigned", "leadCallScriptsAndQuestions": [{"scriptTitle": "Welcome Line",
            "scriptOrder": 1, "forLeadSourceId": 1, "scriptBody": "Hello thank you for calling Community Care, what are you
            interested in?"}, {"scriptTitle": "Qualifying questions", "scriptOrder": 2, "forLeadSourceId": 1, "scriptQuestions":
            [{"questionText": "No bankruptcy in the last 3 years?", "answerType": "checkbox", "questionId": 1, "answer": false,
            "shortDescription": "No bankruptcy"}, {"questionText": "What is your credit score?", "answerType": "text", "questionId":
            2, "answer": "", "shortDescription": "Credit Score"}, {"questionText": "Mortgage Payments On Time? ", "answerType":
            "checkbox", "questionId": 3, "answer": false, "shortDescription": "Mortgage On Time"}, {"questionText": "Taxes Payment
            On Time?", "answerType": "checkbox", "questionId": 4, "answer": false, "shortDescription": "Taxes On Time"},
            {"questionText": "No liens or foreclosure?", "answerType": "checkbox", "questionId": 5, "answer": false,
            "shortDescription": "No Liens/Foreclosure"}, {"questionText": "Pace Approved", "answerType": "checkbox", "questionId":
            6, "answer": false, "shortDescription": "Pace Approved"}], "isQualQuestions": true, "scriptBody": "Please ask the
            following qualification questions:"}], "leadID": 6843, "_id": {"$oid": "627585219c93a48b37e7cd1f"}}}
            `
        }
        
      ]
    },
        // All Leads
        deleteLeadByID: {
          icon: 'FileMinus',
          title: 'Delete Lead By ID',
          subtitle: 'How do I delete one of my leads?',
          qandA: [
            {
              question: 'How do I delete my leads?',
              ans:
                `To get your leads simply submit a DELETE request to ${btnhAPIUrl}deleteLeadWithId  You will need to include your API key (apiKey) and the lead ID (leadID) as a json in the body of the request`
            },
            {
              question: 'What parameters should be included with this request? (API)',
              ans:
                `deleteLeadWithId accepts DELETE requests only, the delete request should have 2 parameters, apiKey (case sensitive) and the lead id to be deleted (leadID). Both parameters should be included in the body of the request as json see next tab (example API request) for example delete request.`
            },
            {
              question: 'Example API request',
              ans:
                `Please include the json request in the body of the request.\n
                {"apiKey": "${localStorage.getItem('apiKey')}", "leadID": 1234}
                `
            },
            {
              question: 'What type of respond should I expect from the API? (API)',
              ans:
              `${btnhAPIUrl}deleteLeadWithId should return a 200 respond code with json data, the json data will include a status, status message and the lead id that was deleted. Example respond in the next tab (Example respond from the API).`
            },
            {
              question: 'Example respond from the API',
              ans:
              `{'status': 'success', 'statusMsg': 'Lead was deleted from DB', 'leadID': 1234}`
            } 
          ]
        }
  }
}

mock.onGet('/faq/data').reply(config => {
  const { q = '' } = config.params
  const queryLowered = q.toLowerCase()

  const filteredData = {}

  Object.entries(data.docData).forEach(entry => {
    const [categoryName, categoryObj] = entry
    const filteredQAndAOfCategory = categoryObj.qandA.filter(qAndAObj => {
      return qAndAObj.question.toLowerCase().includes(queryLowered) || qAndAObj.ans.toLowerCase().includes(queryLowered)
    })
    if (filteredQAndAOfCategory.length) {
      filteredData[categoryName] = { ...categoryObj, qandA: filteredQAndAOfCategory }
    }
  })

  return [200, filteredData]
})
