import mock from '../mock'
import { paginateArray } from '../utils'
import axios from 'axios'
// const data = {
//   users: [
//     {
//       id: 1,
//       fullName: 'Shachaf Zilberman',
//       company: 'BTNH LLC',
//       role: 'admin',
//       premmisions: ['admin'],
//       username: 'gslixby0',
//       country: 'El Salvador',
//       contact: '(479) 232-9151',
//       email: 'gslixby0@abc.net.au',
//       status: 'active',
//       avatar: ''
//     },
//     {
//       id: 2,
//       fullName: 'Liliana Redmore',
//       company: 'BTNH LLC',
//       role: 'dispatcher',
//       premmisions: ['viewleads', 'editleads'],
//       acceptsLeadSource: ['facebook', 'pace'],
//       username: 'lilianar',
//       country: 'Albania',
//       contact: '(472) 607-9137',
//       email: 'hredmore1@imgur.com',
//       status: 'active',
//       avatar: require('@src/assets/images/avatars/10.png').default
//     },
//     {
//       id: 3,
//       fullName: 'Marjory Sicely',
//       company: 'Oozz PVT LTD',
//       role: 'officemanager',
//       premmisions: ['viewleads', 'editleads'],
//       acceptsLeadSource: ['facebook', 'pace'],
//       username: 'msicely2',
//       country: 'Russia',
//       contact: '(321) 264-4599',
//       email: 'msicely2@who.int',
//       status: 'inactive',
//       avatar: require('@src/assets/images/avatars/1.png').default
//     },
//     {
//       id: 4,
//       fullName: 'Cyrill Risby',
//       company: 'Oozz PVT LTD',
//       role: 'accountant',
//       premmisions: ['viewleads', 'editleads'],
//       acceptsLeadSource: ['facebook', 'pace'],
//       username: 'crisby3',
//       country: 'China',
//       contact: '(923) 690-6806',
//       email: 'crisby3@wordpress.com',
//       currentPlan: 'team',
//       status: 'inactive',
//       avatar: require('@src/assets/images/avatars/9.png').default
//     },
//     {
//       id: 5,
//       fullName: 'Maggy Hurran',
//       company: 'Aimbo PVT LTD',
//       role: 'productionmanager',
//       premmisions: ['viewleads', 'editleads'],
//       acceptsLeadSource: ['facebook', 'pace'],
//       username: 'mhurran4',
//       country: 'Pakistan',
//       contact: '(669) 914-1078',
//       email: 'mhurran4@yahoo.co.jp',
//       currentPlan: 'enterprise',
//       status: 'pending',
//       avatar: require('@src/assets/images/avatars/10.png').default
//     },
//     {
//       id: 6,
//       fullName: 'Yois Hurran',
//       company: 'Aimbo PVT LTD',
//       role: 'dispatchermanager',
//       premmisions: ['viewleads', 'editleads'],
//       acceptsLeadSource: ['facebook', 'pace'],
//       username: 'mhurran4',
//       country: 'Pakistan',
//       contact: '(669) 914-1078',
//       email: 'mhurran4@yahoo.co.jp',
//       currentPlan: 'enterprise',
//       status: 'pending',
//       avatar: require('@src/assets/images/avatars/10.png').default
//     },   
//      {
//       id: 7,
//       fullName: 'Noam Hurran',
//       company: 'Aimbo PVT LTD',
//       role: 'salesman',
//       premmisions: ['viewleads', 'editleads'],
//       acceptsLeadSource: ['facebook', 'pace'],
//       username: 'mhurran4',
//       country: 'Pakistan',
//       contact: '(669) 914-1078',
//       email: 'mhurran4@yahoo.co.jp',
//       currentPlan: 'enterprise',
//       status: 'pending',
//       avatar: require('@src/assets/images/avatars/10.png').default
//     },
//     {
//       id: 8,
//       fullName: 'Noam Hurran',
//       company: 'Aimbo PVT LTD',
//       role: 'salesmanmanager',
//       premmisions: ['viewleads', 'editleads'],
//       acceptsLeadSource: ['facebook', 'pace'],
//       username: 'mhurran4',
//       country: 'Pakistan',
//       contact: '(669) 914-1078',
//       email: 'mhurran4@yahoo.co.jp',
//       currentPlan: 'enterprise',
//       status: 'pending',
//       avatar: require('@src/assets/images/avatars/10.png').default
//     },
//     {
//       id: 9,
//       fullName: 'Leon Taoubes',
//       company: 'Aimbo PVT LTD',
//       role: 'leadProvider',
//       premmisions: ['viewleads', 'editleads'],
//       acceptsLeadSource: ['facebook'],
//       username: 'mhurran4',
//       country: 'Pakistan',
//       contact: '(669) 914-1078',
//       email: 'mhurran4@yahoo.co.jp',
//       currentPlan: 'enterprise',
//       status: 'pending',
//       avatar: require('@src/assets/images/avatars/10.png').default
//     }
//   ]
// }

const usersData = {
  users: []
}

// GET ALL DATA
mock.onGet('/api/users/list/all-data').reply(() => {
  return new Promise(function (resolve, reject) {
    axios.get(`https://api.btnh.app/getAllUsers`).then(response => {
      // console.log("Getting all users from DB..")

      if (response.data) {
        // returned value is array and ok.
        console.log('response.data', response.data)
        
        usersData.users = response.data
      }
      console.log('usersData.users', usersData.users)
      
      // return [200, usersData.users]
      resolve([200, usersData.users])
      })
    
  })

})

function generateRandomKey(length) {
  let range = 0
  let result           = ''
  const characters       = 'ABCDEF-GHIJKLM-NOPQRSTU-VWXYZabc-defghijklm-nopqrstuvwxyz0123456789-'
  const charactersLength = characters.length
  while (range < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
    range++
 }
 return result
}
// POST: Add new user
mock.onPost('/apps/users/add-user').reply(config => {
  // Get event from post data
  const user = JSON.parse(config.data)

  const { length } = usersData.users
  let lastIndex = 0
  if (length) {
    lastIndex = usersData.users[length - 1].userID
  }
  user.userID = lastIndex + 1
  user.registrationToken = generateRandomKey(11) + user.userID
  const today = new Date()
  const tomorrow = new Date(today)
  tomorrow.setDate(tomorrow.getDate() + 1)
  user.registrationTokenExpiredDate = tomorrow
  user.registrationTokenUsed = false
  user.status = 'pending'
  // Add user to DB: Permmisions will be added based on rule on the server side
  console.log("Adding new user to DB", user)

  axios.put(`https://api.btnh.app/addNewUser`, user).then(response => {

    if (response.data) {
      // returned value is array and ok.
      console.log('response.data', response.data)
      return [200, 'ok']
    }    
    // return [200, usersData.users]
    })
  usersData.users.unshift(user)

  return [201, { user }]
})

// POST: reset User Password
mock.onPost('/apps/users/reset-password').reply(config => {
  // Get event from post data
  const user = JSON.parse(config.data)
  user.registrationToken = generateRandomKey(11) + user.userID
  const today = new Date()
  const tomorrow = new Date(today)
  tomorrow.setDate(tomorrow.getDate() + 1)
  user.registrationTokenExpiredDate = tomorrow
  user.registrationTokenUsed = false
  user.status = 'pending'
  // Add user to DB: Permmisions will be added based on rule on the server side
  console.log("Reseting password for", user)

  axios.post(`https://api.btnh.app/resetUserPassword`, user).then(response => {

    if ('success' in response.data) {
      // returned value is array and ok.
      console.log('response.data', response.data)
      return [200, 'ok']
    } else {
      return [500, 'fail to reset password']
    }   
    // return [200, usersData.users]
    })

  return [201, { user }]
})


// GET Updated DATA
mock.onGet('/api/users/list/data').reply(config => {
  const { q = '', perPage = 10, page = 1, role = null, status = null } = config

  /* eslint-disable  */
  const queryLowered = q.toLowerCase()
  const filteredData = usersData.users.filter(
    user =>
      (user.username.toLowerCase().includes(queryLowered) || user.fullName.toLowerCase().includes(queryLowered)) &&
      user.role === (role || user.role) &&
      user.status === (status || user.status)
  )
  /* eslint-enable  */

  return [
    200,
    {
      users: paginateArray(filteredData, perPage, page),
      total: filteredData.length
    }
  ]
})

// GET USER
mock.onGet('/api/users/user').reply(config => {
  // console.log('usersData.users:', usersData.users)
  // const user = usersData.users.find(i => i.userID === userID)
  console.log('config', config)
  return new Promise(function (resolve, reject) {
    const { username } = config
    console.log('config->username', username)
    axios.get(`https://api.btnh.app/getUserByUsername?username=${username}`).then(response => {
      console.log("Getting user with ID", username, "From DB")
      if (response.data) {
        // returned value is array and ok.
        console.log('response.data', response.data)
        
        const selectedUser = response.data
              // return [200, { user }]
        if ('error' in selectedUser) {
          resolve([500, undefined])
        }
        resolve([200, selectedUser])
      }
      resolve([500, undefined])

      })
    
  })
  
  
})

// DELETE: Deletes User
mock.onDelete('/apps/users/delete').reply(config => {
  // Get user id from URL
  console.log('config', config)
  
  let userId = config.id
  const username = config.username
  // Convert Id to number
  userId = Number(userId)

  const userIndex = usersData.users.findIndex(t => t.userID === userId)
  usersData.users.splice(userIndex, 1)
  return new Promise(function (resolve, reject) {
    const { username } = config
    console.log('config->username', username)
    axios.delete(`https://api.btnh.app/deleteUser?username=${username}`).then(response => {
      console.log("Deleting user with username", username)
      if ('success' in response.data) {
        // returned value is array and ok.
        console.log('response.data', response.data)
                      // return [200, { user }]
        if ('error' in response.data) {
          resolve([500, undefined])
        }
        resolve([200, 'success'])
      } else {
        resolve([500, undefined])
      } 
      

      })
    
  })
})
