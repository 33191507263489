/*eslint-disable */
import mock from '../mock'
import { useSelector, useDispatch } from 'react-redux'
import { useEffect } from 'react'
import axios from 'axios'

const date = new Date()
const datePlusHour = new Date(new Date().getTime() + 1 * 60 * 60 * 1000)

// prettier-ignore
// const nextMonth = date.getMonth() === 11 ? new Date(date.getFullYear() + 1, 0, 1) : new Date(date.getFullYear(), date.getMonth() + 1, 1)
// // prettier-ignore
// const prevMonth = date.getMonth() === 11 ? new Date(date.getFullYear() - 1, 0, 1) : new Date(date.getFullYear(), date.getMonth() - 1, 1)

const data = {
  events: []
}

// ------------------------------------------------
// GET: Return calendar events
// ------------------------------------------------
mock.onGet('/apps/calendar/events').reply(config => {
  // Get requested calendars as Array
  const calendars = config.calendars
  console.log("config???", config);
  return [200, data.events.filter(event => {
    // console.log("calendars:", calendars )
    // console.log(`event.extendedProps.calendarID: '${event.extendedProps.calendarID}'`)
    // console.log("type of event.extendedProps.calendarID:", typeof event.extendedProps.calendarID)
    // const eventsToInclude = calendars.includes()
    const nameToCHeck = event.extendedProps.calendarID
    console.log("Comparing the value", nameToCHeck, "against", calendars, 'result is', calendars.includes(event.extendedProps.calendarID))
    // console.log()
    // console.log(eventsToInclude)
   return calendars.includes(event.extendedProps.calendarID)
  })
  ]
})

// ------------------------------------------------
// POST: Add new event
// ------------------------------------------------
mock.onPost('/apps/calendar/add-event').reply(config => {
  // Get event from post data
  const { event } = JSON.parse(config.data)
  console.log("config???", config);
  const { length } = data.events
  let lastIndex = 0
  if (length) {
    lastIndex = data.events[length - 1].id
  }
  event.id = lastIndex + 1

  data.events.push(event)

  return [201, { event }]
})

// mock.onGet('/apps/calendar/events').reply(config => {

// })

mock.onGet('/apps/calendar/defaultSchedule').reply(config => {
  return new Promise(function (resolve, reject) {
    axios.get('https://api.btnh.app/getAllSalesmanSchedule').then(result => {
      // console.log("Getting all users from DB..")

      if (result.data) {
        // returned value is array and ok.
        // console.log('result.data', result.data)
        
        // console.log('result.data', result.data)
        resolve([200, result.data])
      }
      
      // return [200, usersData.users]

      })
    
  })
})
// ------------------------------------------------
// POST: Update Event
// ------------------------------------------------
mock.onPost('/apps/calendar/update-event').reply(config => {
  const { event: eventData } = JSON.parse(config.data)
  console.log("config???", config);
  // Convert Id to number
  eventData.id = Number(eventData.id)

  const event = data.events.find(ev => ev.id === Number(eventData.id))
  Object.assign(event, eventData)

  return [200, { event }]
})

// ------------------------------------------------
// DELETE: Remove Event
// ------------------------------------------------
mock.onDelete('/apps/calendar/remove-event').reply(config => {
  // Get event id from URL
  let { id } = config
  console.log("config???", config);
  // Convert Id to number
  const eventId = Number(id)

  const eventIndex = data.events.findIndex(ev => ev.id === eventId)
  data.events.splice(eventIndex, 1)
  return [200]
})
