import mock from '../mock'
import { useState, useEffect } from 'react'
import axios from 'axios'

// const data = {
//   tasks: [
//     {
//       id: 1,
//       title: 'Entire change break our wife wide it daughter mention member.',
//       dueDate: '2020-11-25',
//       description:
//         'Chocolate cake topping bonbon jujubes donut sweet wafer. Marzipan gingerbread powder brownie bear claw. Chocolate bonbon sesame snaps jelly caramels oat cake.',
//       assignee: {
//         fullName: 'Shcafon Tester',
//         avatar: require('@src/assets/images/avatars/7.png').default,
//         username: 'tester'
//       },
//       tags: ['update'],
//       isCompleted: false,
//       isDeleted: false,
//       isImportant: false
//     },
//     {
//       id: 2,
//       title: 'Citizen stand administration step agency century.',
//       dueDate: '2020-12-14',
//       description:
//         'Chocolate cake topping bonbon jujubes donut sweet wafer. Marzipan gingerbread powder brownie bear claw. Chocolate bonbon sesame snaps jelly caramels oat cake.',
//       assignee: {
//         fullName: 'Shcafon Tester',
//         avatar: require('@src/assets/images/avatars/7.png').default,
//         username: 'tester'
//       },
//       tags: ['team', 'medium'],
//       isCompleted: true,
//       isDeleted: false,
//       isImportant: false
//     },
//     {
//       id: 3,
//       title: 'Meet Jane and ask for coffee ❤️',
//       dueDate: '2020-11-25',
//       description:
//         'Chocolate cake topping bonbon jujubes donut sweet wafer. Marzipan gingerbread powder brownie bear claw. Chocolate bonbon sesame snaps jelly caramels oat cake.',
//       assignee: {
//         fullName: 'Shcafon Tester',
//         avatar: require('@src/assets/images/avatars/7.png').default,
//         username: 'tester'
//       },
//       tags: ['high'],
//       isCompleted: false,
//       isDeleted: false,
//       isImportant: false
//     },
//     {
//       id: 4,
//       title: 'Answer the support tickets and close completed tickets. ',
//       dueDate: '2020-11-20',
//       description:
//         'Chocolate cake topping bonbon jujubes donut sweet wafer. Marzipan gingerbread powder brownie bear claw. Chocolate bonbon sesame snaps jelly caramels oat cake.',
//       assignee: {
//         fullName: 'Shcafon Tester',
//         avatar: require('@src/assets/images/avatars/7.png').default,
//         username: 'tester'
//       },
//       tags: ['medium'],
//       isCompleted: false,
//       isDeleted: false,
//       isImportant: true
//     },
//     {
//       id: 5,
//       title: 'Test functionality of apps developed by dev team for enhancements. ',
//       dueDate: '2020-12-06',
//       description:
//         'Chocolate cake topping bonbon jujubes donut sweet wafer. Marzipan gingerbread powder brownie bear claw. Chocolate bonbon sesame snaps jelly caramels oat cake.',
//       assignee: {
//         fullName: 'Shcafon Tester',
//         avatar: require('@src/assets/images/avatars/7.png').default,
//         username: 'tester'
//       },
//       tags: ['medium'],
//       isCompleted: true,
//       isDeleted: false,
//       isImportant: true
//     },
//     {
//       id: 6,
//       title: 'Conduct a mini awareness meeting regarding health care. ',
//       dueDate: '2020-12-06',
//       description:
//         'Chocolate cake topping bonbon jujubes donut sweet wafer. Marzipan gingerbread powder brownie bear claw. Chocolate bonbon sesame snaps jelly caramels oat cake.',
//       assignee: {
//         fullName: 'Shcafon Tester',
//         avatar: require('@src/assets/images/avatars/7.png').default,
//         username: 'tester'
//       },
//       tags: ['high', 'medium'],
//       isCompleted: true,
//       isDeleted: true,
//       isImportant: false
//     },
//     {
//       id: 7,
//       title: 'Plan new dashboard design with design team for Google app store. ',
//       dueDate: '2020-12-05',
//       description:
//         'Chocolate cake topping bonbon jujubes donut sweet wafer. Marzipan gingerbread powder brownie bear claw. Chocolate bonbon sesame snaps jelly caramels oat cake.',
//       assignee: {
//         fullName: 'Shcafon Tester',
//         avatar: require('@src/assets/images/avatars/7.png').default,
//         username: 'tester'
//       },
//       tags: ['medium'],
//       isCompleted: false,
//       isDeleted: false,
//       isImportant: true
//     },
//     {
//       id: 8,
//       title: 'Pick up Nats from her school and drop at dance class😁 ',
//       dueDate: '2020-12-08',
//       description:
//         'Chocolate cake topping bonbon jujubes donut sweet wafer. Marzipan gingerbread powder brownie bear claw. Chocolate bonbon sesame snaps jelly caramels oat cake.',
//       assignee: {
//         fullName: 'Shcafon Tester',
//         avatar: require('@src/assets/images/avatars/7.png').default,
//         username: 'tester'
//       },
//       tags: ['low', 'medium'],
//       isCompleted: false,
//       isDeleted: false,
//       isImportant: false
//     },
//     {
//       id: 9,
//       title: 'Finish documentation and make it live',
//       dueDate: '2020-11-25',
//       description:
//         'Chocolate cake topping bonbon jujubes donut sweet wafer. Marzipan gingerbread powder brownie bear claw. Chocolate bonbon sesame snaps jelly caramels oat cake.',
//       assignee: {
//         fullName: 'Shcafon Tester',
//         avatar: require('@src/assets/images/avatars/7.png').default,
//         username: 'tester'
//       },
//       tags: ['high', 'update'],
//       isCompleted: false,
//       isDeleted: true,
//       isImportant: false
//     },
//     {
//       id: 10,
//       title: 'List out all the SEO resources and send it to new SEO team. ',
//       dueDate: '2020-12-09',
//       description:
//         'Chocolate cake topping bonbon jujubes donut sweet wafer. Marzipan gingerbread powder brownie bear claw. Chocolate bonbon sesame snaps jelly caramels oat cake.',
//       assignee: {
//         fullName: 'Karen Carrillo',
//         avatar: require('@src/assets/images/portrait/small/avatar-s-9.jpg').default,
//         username: 'sivanosh'
//       },
//       tags: ['low'],
//       isCompleted: true,
//       isDeleted: false,
//       isImportant: false
//     },
//     {
//       id: 11,
//       title: 'Refactor Code and fix the bugs and test it on server ',
//       dueDate: '2020-12-01',
//       description:
//         'Chocolate cake topping bonbon jujubes donut sweet wafer. Marzipan gingerbread powder brownie bear claw. Chocolate bonbon sesame snaps jelly caramels oat cake.',
//       assignee: {
//         fullName: 'Liliana Carrillo',
//         avatar: require('@src/assets/images/portrait/small/avatar-s-6.jpg').default,
//         username: 'lilianac'
//       },
//       tags: ['low'],
//       isCompleted: false,
//       isDeleted: false,
//       isImportant: true
//     },
//     {
//       id: 12,
//       title: 'Reminder to mail clients for holidays',
//       dueDate: '2020-12-09',
//       description:
//         'Chocolate cake topping bonbon jujubes donut sweet wafer. Marzipan gingerbread powder brownie bear claw. Chocolate bonbon sesame snaps jelly caramels oat cake.',
//       assignee: {
//         fullName: 'Marthy Sarrillo',
//         avatar: require('@src/assets/images/portrait/small/avatar-s-10.jpg').default,
//         username: 'marthys'
//       },
//       tags: ['team'],
//       isCompleted: false,
//       isDeleted: false,
//       isImportant: false
//     },
//     {
//       id: 13,
//       title: "Submit quotation for Abid's ecommerce website and admin project",
//       dueDate: '2020-12-01',
//       description:
//         'Chocolate cake topping bonbon jujubes donut sweet wafer. Marzipan gingerbread powder brownie bear claw. Chocolate bonbon sesame snaps jelly caramels oat cake.',
//       assignee: {
//         fullName: 'Karen Carrillo',
//         avatar: require('@src/assets/images/portrait/small/avatar-s-9.jpg').default,
//         username: 'sivanosh'
//       },
//       tags: ['team'],
//       isCompleted: false,
//       isDeleted: false,
//       isImportant: false
//     },
//     {
//       id: 14,
//       title: 'Send PPT with real-time reports',
//       dueDate: '2020-11-29',
//       description:
//         'Chocolate cake topping bonbon jujubes donut sweet wafer. Marzipan gingerbread powder brownie bear claw. Chocolate bonbon sesame snaps jelly caramels oat cake.',
//       assignee: {
//         fullName: 'Karen Carrillo',
//         avatar: require('@src/assets/images/portrait/small/avatar-s-9.jpg').default,
//         username: 'sivanosh'
//       },
//       tags: ['medium'],
//       isCompleted: true,
//       isDeleted: false,
//       isImportant: true
//     },
//     {
//       id: 15,
//       title: 'Skype Tommy for project status & report',
//       dueDate: '2020-11-29',
//       description:
//         'Chocolate cake topping bonbon jujubes donut sweet wafer. Marzipan gingerbread powder brownie bear claw. Chocolate bonbon sesame snaps jelly caramels oat cake.',
//       assignee: {
//         fullName: 'Karen Carrillo',
//         avatar: require('@src/assets/images/portrait/small/avatar-s-9.jpg').default,
//         username: 'sivanosh'
//       },
//       tags: ['medium'],
//       isCompleted: false,
//       isDeleted: false,
//       isImportant: false
//     },
//     {
//       id: 16,
//       title: 'Hire 5 new Fresher or Experienced, frontend and backend developers ',
//       dueDate: '2020-12-12',
//       description:
//         'Chocolate cake topping bonbon jujubes donut sweet wafer. Marzipan gingerbread powder brownie bear claw. Chocolate bonbon sesame snaps jelly caramels oat cake.',
//       assignee: {
//         fullName: 'Karen Carrillo',
//         avatar: require('@src/assets/images/portrait/small/avatar-s-9.jpg').default,
//         username: 'sivanosh'
//       },
//       tags: ['low'],
//       isCompleted: true,
//       isDeleted: false,
//       isImportant: false
//     },
//     {
//       id: 17,
//       title: 'Plan a party for development team 🎁',
//       dueDate: '2020-12-04',
//       description:
//         'Chocolate cake topping bonbon jujubes donut sweet wafer. Marzipan gingerbread powder brownie bear claw. Chocolate bonbon sesame snaps jelly caramels oat cake.',
//       assignee: {
//         fullName: 'Karen Carrillo',
//         avatar: require('@src/assets/images/portrait/small/avatar-s-9.jpg').default,
//         username: 'sivanosh'
//       },
//       tags: ['medium', 'low'],
//       isCompleted: false,
//       isDeleted: false,
//       isImportant: false
//     },
//     {
//       id: 18,
//       title: 'Fix Responsiveness for new structure 💻',
//       dueDate: '2020-11-18',
//       description:
//         'Chocolate cake topping bonbon jujubes donut sweet wafer. Marzipan gingerbread powder brownie bear claw. Chocolate bonbon sesame snaps jelly caramels oat cake.',
//       assignee: {
//         fullName: 'Karen Carrillo',
//         avatar: require('@src/assets/images/portrait/small/avatar-s-9.jpg').default,
//         username: 'sivanosh'
//       },
//       tags: ['low'],
//       isCompleted: false,
//       isDeleted: false,
//       isImportant: true
//     }
//   ]
// }
const data = []
// Every user will hace this value under his user date ( in local storage )
// This array is made of employees that the user is manageing
// We use this to determine whos tasks the user can see and assign to 
// const userManageEmployees = [{value: 'sivanosh', label: 'Sivan Levy'}, {value: 'lilianac', label: 'Liliana Ca'}]
// const userManageEmployees = [{value: 'sivanosh', label: 'Sivan Levy'}, {value: 'lilianac', label: 'Liliana Ca'}]
const currentUser = JSON.parse(localStorage.getItem('userData'))
// ------------------------------------------------
// GET: Return Tasks
// ------------------------------------------------

function loadAndFilterTasks(config) {
  let userManageEmployees = []
  const currentUser = JSON.parse(localStorage.getItem('userData'))
  const { q = '', filter, tag, sortBy: sortByParam = 'sortID' } = config.params

  const username = JSON.parse(localStorage.getItem('userData')).username
  if (currentUser.hasOwnProperty('manageTasksForUsers')) {
    userManageEmployees = currentUser.manageTasksForUsers
  } 
  /* eslint-enable */
  // ------------------------------------------------
  // Get Sort by and Sort Direction
  // ------------------------------------------------
  let sortDesc = true
  console.log('filter', filter)
  console.log('tag', tag)
  // console.log('sortBy', sortBy)
  const sortBy = (() => {
    if (sortByParam === 'title-asc') {
      sortDesc = false
      return 'title'
    }
    if (sortByParam === 'title-desc') return 'title'
    if (sortByParam === 'assignee') {
      sortDesc = false
      return 'assignee'
    }
    if (sortByParam === 'due-date') {
      sortDesc = false
      return 'dueDate'
    }
    sortDesc = false
    return 'sortID'
  })()

  // ------------------------------------------------
  // Filtering
  // ------------------------------------------------
  const queryLowered = q.toLowerCase()

  const hasFilter = task => {
    console.log('task', task)
    console.log('filter', filter)
    // console.log('userManageEmployees', userManageEmployees)
    // if (filter === 'myTasks') 
    if (filter === '' || filter === 'tasks') return !task.isDeleted && task.assignee.username === username && !task.isCompleted // default
    if (filter === 'myCompletedTasks') return !task.isDeleted && task.assignee.username === username && task.isCompleted
    if (filter === 'teamTasks') return userManageEmployees.some(emp => emp.value === task.assignee.username) && !task.isDeleted && !task.isCompleted // Get the tasks that the user can see
    if (filter === 'teamCompletedTasks') return userManageEmployees.some(emp => emp.value === task.assignee.username) && task.isCompleted && !task.isDeleted
    if (filter === 'deleted') return (userManageEmployees.some(emp => emp.value === task.assignee.username) || task.assignee.username === username) && task.isDeleted 
    if (userManageEmployees.some(emp => emp.value === filter)) return task.assignee.username === filter && !task.isDeleted && !task.isCompleted 
  }
  /* eslint-disable */
  const filteredData = data.tasks.filter(task => {
    console.log('Returning:', task.title.toLowerCase().includes(queryLowered) && hasFilter(task) && (tag ? task.tags.includes(tag) : true))
    const queryString = window.location.search
    
    // const urlParams = 
    return task.title.toLowerCase().includes(queryLowered) && hasFilter(task) && (new URLSearchParams(queryString).has('tag') ? task.tags.includes(new URLSearchParams(queryString).get('tag')) : true)
  })
  /* eslint-enable  */

  // ------------------------------------------------
  // Perform sorting
  // ------------------------------------------------
  const sortTasks = key => (a, b) => {
    let fieldA
    let fieldB
    console.log('Sort by:', key)
    // If sorting is by dueDate => Convert data to date
    if (key === 'dueDate') {
      fieldA = new Date(a[key])
      fieldB = new Date(b[key])
      // eslint-disable-next-line brace-style
    }

    // If sorting is by assignee => Use `fullName` of assignee
    else if (key === 'assignee') {
      fieldA = a.assignee ? a.assignee.fullName : null
      fieldB = b.assignee ? b.assignee.fullName : null
    } else {
      fieldA = a[key]
      fieldB = b[key]
    }

    let comparison = 0

    if (fieldA === fieldB) {
      comparison = 0
    } else if (fieldA === null) {
      comparison = 1
    } else if (fieldB === null) {
      comparison = -1
    } else if (fieldA > fieldB) {
      comparison = 1
    } else if (fieldA < fieldB) {
      comparison = -1
    }

    return comparison
  }

  // Sort Data
  const sortedData = filteredData.sort(sortTasks(sortBy))
  if (sortDesc) sortedData.reverse()
  
  return sortedData
  // return [200, sortedData]

}
mock.onGet('/apps/todo/tasks').reply(config => {
  // eslint-disable-next-line object-curly-newline
  
  return new Promise(function (resolve, reject) {
    if (data.tasks?.length > 0) {
      // const sortedData = 
      // sortedData.for
      console.log('Using existing data')
      resolve([200, loadAndFilterTasks(config)])
    } else {
      axios.get(`https://api.btnh.app/todoTasks`).then(response => {
        console.log('tasks from DB', response.data)
        data['tasks'] = response.data // assign the tasks from the DB to the data var
        // const sortedData = loadAndFilterTasks(config)
        // console.log('returning data:', sortedData)
        resolve([200, loadAndFilterTasks(config)])
      })
    }

})
})

// ------------------------------------------------
// POST: Add new task
// ------------------------------------------------
mock.onPost('/apps/todo/add-tasks').reply(config => {
  // Get event from post data
  return new Promise(function (resolve, reject) {
    const { task } = JSON.parse(config.data)
  
    // data.tasks.push(task)
    console.log('Adding new task to DB', task)
    axios.put(`https://api.btnh.app/todoTasks`, task).then(response => {
      console.log('server respind to add', response.data)
      const newTask = task
      newTask.sortID = 0
      newTask.id = Number(response.data.id)
      const sortedAsSordIDData = []
      sortedAsSordIDData.push(newTask)
      data.tasks.forEach((task, index) => {
        task.sortID = index + 1
        sortedAsSordIDData.push(task)
      })
      data.tasks = sortedAsSordIDData
      console.log('data.tasks', data.tasks)
      resolve([201, { task }])
    })
    
  })
})
// ------------------------------------------------
// POST: Update Task
// ------------------------------------------------
mock.onPost('/apps/todo/update-task').reply(config => {
  const taskData = JSON.parse(config.data).task
  console.log('config', config)
  console.log('taskData', taskData)
  return new Promise(function (resolve, reject) {
  // Convert Id to number
  taskData.id = Number(taskData.id)
  axios.post(`https://api.btnh.app/todoTasks`, taskData).then(response => {
    console.log('server respind to update', response.data)
  })
  
  const task = data.tasks.find(e => e.id === Number(taskData.id))
  Object.assign(task, taskData)
  resolve([200, { task }])
})
  
})

// ------------------------------------------------
// DELETE: Remove Task
// ------------------------------------------------
mock.onDelete('/apps/todo/delete-task').reply(config => {
  
  // Get task id from URL
  let taskId = config.taskId

  // Convert Id to number
  taskId = Number(taskId)
  const task = data.tasks.find(t => t.id === taskId)
  Object.assign(task, { isDeleted: true })
  axios.post(`https://api.btnh.app/todoTasks`, task).then(response => {
  })
  return [201, { task }]
})
