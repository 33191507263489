import mock from '../mock'

export const searchArr = [
  {
    groupTitle: 'Pages',
    searchLimit: 4,
    data: [
      {
        id: 1,
        target: 'analyticsDash',
        isBookmarked: false,
        title: 'Analytics Dashboard',
        icon: 'Home',
        link: '/dashboard/analytics'
      },
      {
        id: 2,
        target: 'Dashboard',
        isBookmarked: true,
        title: 'eCommerce Dashboard',
        icon: 'Home',
        link: '/'
      },
      {
        id: 5,
        target: 'todo',
        isBookmarked: true,
        title: 'Todo',
        icon: 'CheckSquare',
        link: '/apps/todo'
      },
      {
        id: 6,
        target: 'calendar',
        isBookmarked: false,
        title: 'Calendar',
        icon: 'Calendar',
        link: '/apps/calendar'
      },
      {
        id: 15,
        target: 'userList',
        isBookmarked: false,
        title: 'User List',
        icon: 'User',
        link: '/apps/user/list'
      },
      {
        id: 16,
        target: 'userView',
        isBookmarked: false,
        title: 'User View',
        icon: 'User',
        link: '/apps/user/view'
      },
      {
        id: 17,
        target: 'userEdit',
        isBookmarked: false,
        title: 'User Edit',
        icon: 'User',
        link: '/apps/user/edit'
      },
      
      {
        id: 26,
        target: 'accountSettings',
        isBookmarked: false,
        title: 'Account Settings',
        icon: 'Settings',
        link: '/pages/account-settings'
      },
      {
        id: 35,
        target: 'notAuthorized',
        isBookmarked: false,
        title: 'Not Authorized Page',
        icon: 'UserX',
        link: '/misc/not-authorized'
      }
    ]
  },
  {
    groupTitle: 'Files',
    searchLimit: 4,
    data: [
      {
        title: 'Passport Image',
        by: 'Oliver Queen',
        size: '52kb',
        file: require('@src/assets/images/icons/jpg.png').default
      },
      {
        title: 'Parenting Guide',
        by: 'Alfred Pennyworth',
        size: '2.3mb',
        file: require('@src/assets/images/icons/doc.png').default
      },
      {
        title: 'Class Notes',
        by: 'Barry Allen',
        size: '30kb',
        file: require('@src/assets/images/icons/doc.png').default
      },
      {
        title: 'Class Attendance',
        by: 'Walter White',
        size: '52mb',
        file: require('@src/assets/images/icons/xls.png').default
      }
    ]
  },
  {
    groupTitle: 'Contacts',
    searchLimit: 4,
    data: [
      {
        title: 'Mia Davis',
        email: 'miadavis@teleworm.us',
        img: require('@src/assets/images/portrait/small/avatar-s-8.jpg').default,
        date: '01/03/2020'
      },
      {
        title: 'Norris Carrière',
        email: 'NorrisCarriere@rhyta.com',
        img: require('@src/assets/images/portrait/small/avatar-s-3.jpg').default,
        date: '07/03/2020'
      },
      {
        title: 'Charlotte Gordon',
        email: 'CharlotteGordon@jourrapide.com',
        img: require('@src/assets/images/portrait/small/avatar-s-26.jpg').default,
        date: '14/03/2020'
      },
      {
        email: 'RobertNash@dayrep.com',
        img: require('@src/assets/images/portrait/small/avatar-s-25.jpg').default,
        date: '21/03/2020'
      }
    ]
  }
]

// GET Search Data
mock.onGet('/api/main-search/data').reply(config => {
  return [200, { searchArr }]
})

// GET Search Data & Bookmarks
mock.onGet('/api/bookmarks/data').reply(config => {
  const bookmarks = searchArr[0].data.filter(item => item.isBookmarked)
  const suggestions = searchArr[0].data
  return [200, { suggestions, bookmarks }]
})

// POST Update isBookmarked
mock.onPost('/api/bookmarks/update').reply(config => {
  const { id } = JSON.parse(config.data)

  const obj = searchArr[0].data.find(item => item.id === id)

  Object.assign(obj, { isBookmarked: !obj.isBookmarked })

  return [200]
})
