// const date = new Date()
// const datePlusHour = new Date(new Date().getTime() + 1 * 60 * 60 * 1000)

// ** Initial State
const initialState = {
  events: [],
  selectedEvent: {},
  selectedCalendars: [],
  currentStartRange: "",
  currentEndRange: ""  
}

const calenderReducer = (state = initialState, action) => {
  // console.log("state:", state)
  // console.log("action:", action)
  switch (action.type) {
    case 'FETCH_EVENTS_SALESMAN_SCH': // FETCH_EVENTS
      return { ...state, events: action.events }
    case 'ADD_EVENT_SALESMAN_SCH': // ADD_EVENT
      return { ...state }
    case 'REMOVE_EVENT_SALESMAN_SCH': // REMOVE_EVENT
      return { ...state }
    case 'UPDATE_EVENT_SALESMAN_SCH': // UPDATE_EVENT
      return { ...state }
    case 'UPDATE_FILTERS_SALESMAN_SCH': // UPDATE_FILTERS
      // ** Updates Filters based on action filter
      const filterIndex = state.selectedCalendars.findIndex(i => i === action.filter)
      if (state.selectedCalendars.includes(action.filter)) {
        state.selectedCalendars.splice(filterIndex, 1)
      } else {
        state.selectedCalendars.push(action.filter)
      }
      if (state.selectedCalendars.length === 0) {
        state.events.length = 0
      }
      return { ...state }
    case 'UPDATE_ALL_FILTERS_SALESMAN_SCH': // 
      // ** Updates All Filters based on action value
      const value = action.value
      let selected = []
      if (value === true) {
        selected = ['omerpriwer', 'omerrotman', 'shachafzilberman']
      } else {
        selected = []
      }
      return { ...state, selectedCalendars: selected }
    case 'SELECT_EVENT_SALESMAN_SCH': // SELECT_EVENT
      return { ...state, selectedEvent: action.event }
    default:
      return state
  }
}

export default calenderReducer
